import axios from "axios";
import { Message } from "element-ui";
import { TEST_BASE_API, BASE_API } from "./urls";
import router from "../router/index";
import { getToken, removeToken } from "@/utils/auth";

var Axios = axios.create({
  baseURL: process.env.NODE_ENV === "development" ? TEST_BASE_API : BASE_API,
  timeout: 500000,
});

Axios.interceptors.request.use(
  (config) => {
    let token = getToken();
    config.headers = Object.assign(config.headers ? config.headers : {}, {
      Authorization: token ? 'Bearer ' + token : "",
      from: "pc",
      lang: "zh",
    });
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

Axios.interceptors.response.use(
  (response) => {
    const res = response.data;
    // const code = response.status;
    return res;
  },
  (error) => {
    const code = error.response.status;
    if (code == 401) {
      removeToken();
      Message({
        message: "未登录，请先登录",
        type: "error",
        duration: 5000,
      });
      //跳转到登录页
      // router.replace({ name: "login" });
      router.replace({ name: "login" });
      return Promise.reject("未登录，请先登录");
    } 
    const res = error.response.data.error.validationErrors?error.response.data.error.validationErrors[0].message:error.response.data.error.message?error.response.data.error.message:'出错了，请联系管理员'
    
    console.log(code)
    if (code == 400) {
      Message({
        message: res,
        type: "error",
        duration: 5000,
      });
      return Promise.reject(res);
    } 
    // else if (code == 403) {
    //   removeToken();
    //   Message({
    //     message: "登录过期，请重新登录",
    //     type: "error",
    //     duration: 5000,
    //   });
    //   //跳转到登录页
    //   router.replace({ name: "login" });
    //   return Promise.reject("登录过期，请重新登录");
    // } 
    else {
      // 其他错误
      Message({
        message: res,
        type: "error",
        duration: 5000,
      });
      return Promise.reject(res);
    }
  }
);
export default Axios;
